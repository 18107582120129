import { NgModule } from '@angular/core';

import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [MatMenuModule, MatListModule, MatDividerModule, MatButtonModule, DragDropModule],
    exports: [MatMenuModule, MatListModule, MatDividerModule, MatButtonModule, DragDropModule],
    declarations: [],
})
export class MaterialModule {}
