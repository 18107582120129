import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { HttpService } from './http.service';

import { ErrorHandlerService } from '../services/error-handler.service';
import { MenuDataService } from './menudataservice.service';
import { MenuService } from './menu.service';

@Injectable()
export class AuthorizationService {
    constructor(
        private httpService: HttpService,
        private errorhandlerservice: ErrorHandlerService,
        private menuDataService: MenuDataService,
        private menuService: MenuService
    ) {}

    GetUserApis(token, authorizationVersion) {
        this.getApisFromApi(token, authorizationVersion).subscribe(
            (data) => {
                var apis = data.body;
                localStorage.setItem('currentUserApis', JSON.stringify(apis));
            },
            (error) => {
                this.errorhandlerservice.handleHttpErrorWithSW(error);
            }
        );
    }

    GetUserPages(token, authorizationVersion) {
        this.getPagesFromApi(token, authorizationVersion).subscribe(
            (data) => {
                var pages = data.body;
                localStorage.setItem('currentUserPages', JSON.stringify(pages));
            },
            (error) => {
                this.errorhandlerservice.handleHttpErrorWithSW(error);
            }
        );
    }

    GetUserMenus(token, authorizationVersion) {
        this.getMenusFromApi(token, authorizationVersion)
            .pipe(
                finalize(() => {
                    //changingMenu
                    var newmenu = this.menuService.getJsonMenuFromLocalStorage();
                    this.menuDataService.updateMenu(newmenu);
                })
            )
            .subscribe(
                (data) => {
                    var menus = data.body;
                    let applicationVersion = data.headers.get('application-version');
                    if (applicationVersion) localStorage.setItem('application-version', applicationVersion.toString());
                    localStorage.setItem('currentUserMenus', JSON.stringify(menus));
                },
                (error) => {
                    this.errorhandlerservice.handleHttpErrorWithSW(error);
                }
            );
    }

    private getApisFromApi(token, AuthorizationVersion) {
        let headers = new HttpHeaders();
        return this.httpService.httpGet('Authorization/GetApis', headers, null, true, false);
    }

    private getPagesFromApi(token, AuthorizationVersion) {
        let headers = new HttpHeaders();
        return this.httpService.httpGet('Authorization/GetPages', headers, null, true, false);
    }

    private getMenusFromApi(token, AuthorizationVersion) {
        let headers = new HttpHeaders();
        return this.httpService.httpGet('Authorization/GetMenus', headers, null, true, false);
    }
}
