import { Injectable } from '@angular/core';

export enum MeteringReadingStatusEnum {
    Usable = 0,
    NoTelegram = 1,
    NoReference = 2,
    WeirdValue = 3,
    NegativeValue = 4,
    LastReadingNotUsable = 5,
    NegativeDelta = 6,
    UsedReading = 7,
    Savable = 8,
    HaveUptodateReading = 9,
    MismatchSerialNumber = 10,
    ReadingAfterSlaveEndDate = 11,
}

export enum MeteringReadingTypeEnum {
    TelegramReading = 1,
    ManualReading = 2,
    SystemGenerated = 3,
}

export enum MeteringAccountsTypeEnum {
    ExpenseAccountID = 0,
    IncomeAccountID = 1,
    CurrentAccountID = 3,
}

@Injectable()
export class EnumsService {
    constructor() {}

    getMeteringReadingStatusEnum(statusID: number) {
        switch (statusID) {
            case 0: {
                return 'Kullanılabilir';
            }
            case 1: {
                return 'Telegram yok';
            }
            case 2: {
                return 'Referans yok';
            }
            case 3: {
                return 'Garip değer';
            }
            case 4: {
                return 'Negatif değer';
            }
            case 5: {
                return 'Son okuma geçersiz';
            }
            case 6: {
                return 'Negatif fark';
            }
            case 7: {
                return 'Okuma yok';
            }
            case 8: {
                return 'OK';
            }
            case 9: {
                return 'Daha yeni okuma var';
            }
            case 10: {
                return 'Seri Numara eşleşmedi';
            }
            case 11: {
                return 'Sayaç sökülmüştür.';
            }
            default: {
                return '';
            }
        }
    }

    getMeteringReadingTypeEnum(typeID: number) {
        switch (typeID) {
            case 1: {
                return 'Telegram okuması';
            }
            case 2: {
                return 'Manuel okuma';
            }
            case 3: {
                return 'Sistem tarafından üretilen okuma';
            }
            default: {
                return '';
            }
        }
    }

    getMeteringValveState(stateID: number) {
        // ID	Code	Name
        // 0	Unknown	Bilinmiyor
        // 1	Open	Açık
        // 2	Closed	Kapalı
        // 3	InProcess	İşlem Yapıyor

        switch (stateID) {
            case 0: {
                return 'Bilinmiyor';
            }
            case 1: {
                return 'Açık';
            }
            case 2: {
                return 'Kapalı';
            }
            case 3: {
                return 'İşlem Yapıyor';
            }
            default: {
                return '';
            }
        }
    }
}
