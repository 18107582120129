import { Component, Input } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'datagrid-export-button',
    templateUrl: './datagrid-export-button.component.html',
})
export class DataGridExportButtonComponent {
    @Input() dataGrid: DxDataGridComponent;
    @Input() onlySelected: boolean;
    @Input() exportedFileName: string;
    // @Input() excludedColumnsNames: string[];

    ExportToExcel() {
        // Hide columns to be exclude in exported excel
        // this.dataGrid.instance.option(
        //     'onExporting', args =>{
        //         this.dataGrid.instance.beginUpdate();
        //         if (this.excludedColumnsNames) {
        //             for (let columName of this.excludedColumnsNames) {
        //                 this.dataGrid.instance.columnOption(columName, "visible", false);
        //             }
        //         }
        //     }
        // );

        //Create FileName
        let excelFileName = this.exportedFileName;
        this.dataGrid.instance.option({
            export: {
                enabled: false,
                fileName: excelFileName + ' ' + this.GetDateString(new Date(), 'FileName'),
            },
        });

        //Export to Excel
        this.dataGrid.instance.exportToExcel(this.onlySelected);

        // Unhide columns
        // this.dataGrid.instance.option(
        //     'onExported', args =>{
        //         if (this.excludedColumnsNames) {
        //             for (let columName of this.excludedColumnsNames) {
        //                 this.dataGrid.instance.columnOption(columName, "visible", true);
        //             }
        //         }
        //         this.dataGrid.instance.endUpdate();
        //     }
        // );
    }

    GetDateString(_Date, Type) {
        var string = '';
        var day, month, year, hour, min;
        if (Type == 'FileName') {
            day = _Date.getDate().toString().length == 1 ? '0' + _Date.getDate() : _Date.getDate();
            month = (_Date.getMonth() + 1).toString().length == 1 ? '0' + (_Date.getMonth() + 1) : _Date.getMonth() + 1;
            year = _Date.getFullYear();
            hour = _Date.getHours().toString().length == 1 ? '0' + _Date.getHours() : _Date.getHours();
            min = _Date.getMinutes().toString().length == 1 ? '0' + _Date.getMinutes() : _Date.getMinutes();

            string = year + '-' + month + '-' + day + ' ' + hour + '-' + min;
        } else if (Type == 'Date') {
            day = _Date.getDate().toString().length == 1 ? '0' + _Date.getDate() : _Date.getDate();
            month = (_Date.getMonth() + 1).toString().length == 1 ? '0' + (_Date.getMonth() + 1) : _Date.getMonth() + 1;
            year = _Date.getFullYear();
            string = day + '.' + month + '.' + year;
        } else if (Type == 'DateTime') {
            day = _Date.getDate().toString().length == 1 ? '0' + _Date.getDate() : _Date.getDate();
            month = (_Date.getMonth() + 1).toString().length == 1 ? '0' + (_Date.getMonth() + 1) : _Date.getMonth() + 1;
            year = _Date.getFullYear();
            hour = _Date.getHours().toString().length == 1 ? '0' + _Date.getHours() : _Date.getHours();
            min = _Date.getMinutes().toString().length == 1 ? '0' + _Date.getMinutes() : _Date.getMinutes();

            string = day + '.' + month + '.' + year + ' ' + hour + ':' + min;
        } else if (Type == 'Year') {
            year = _Date.getFullYear();
            string = year;
        } else if (Type == 'MonthYear') {
            month = (_Date.getMonth() + 1).toString().length == 1 ? '0' + (_Date.getMonth() + 1) : _Date.getMonth() + 1;
            year = _Date.getFullYear();
            string = month + '.' + year;
        }

        return string;
    }
}
