//Sentry
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
import { Injectable, ErrorHandler } from '@angular/core';

Sentry.init({
    dsn: 'https://c5434e459ce04abd9c32ff838d96c118@sentry.io/1530453',
    environment: environment.name,
    beforeSend(event, hint) {
        /* tslint:disable:no-string-literal only-arrow-functions */
        const isNonErrorException =
            event.exception.values[0].value.startsWith('Non-Error exception captured') ||
            hint.originalException['message'].startsWith('Non-Error exception captured');
        /* tslint:enable:no-string-literal only-arrow-functions */
        if (isNonErrorException) {
            // We want to ignore those kind of errors
            // console.log(JSON.stringify(event, null, 2));
            return null;
        }
        return event;
    },
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {}
    handleError(error) {
        //if it is development don't capture
        if (!environment.test && !environment.production) {
            console.error(error);
        } else {
            //capture all errors on test and production
            const eventId = Sentry.captureException(error.originalError || error);

            //if it is test get user report
            // if (environment.test)
            //   Sentry.showReportDialog({ eventId });
        }
    }
}
