import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { SharedModule } from './shared/shared.module';

//Routing
import { AppRoutingModule } from './app.routing.module';

//3rd parti plugins
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { CookieModule } from 'ngx-cookie';

//Services
import { Configuration } from './app.constants';
import { AppService } from './app.service';
import { MenuService } from './shared/services/menu.service';
import { MenuDataService } from './shared/services/menudataservice.service';

import { CoreSystemService } from './pages/core-system/core-system.service';

//Localiz devextreme
let messagestr = require('../assets/Localization/Devextreme/tr.json');
import { locale, loadMessages } from 'devextreme/localization';
loadMessages(messagestr);
locale('tr-TR');

//Import 'devextreme/integration/jquery';
import config from 'devextreme/core/config';
config({ forceIsoDateParsing: false });

//Localize
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
registerLocaleData(localeTr, 'tr-TR');

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        CookieModule.forRoot(),
        SideNavOuterToolbarModule,
        SideNavInnerToolbarModule,
        SingleCardModule,
        SharedModule,
        AppRoutingModule,
        NgProgressModule.withConfig({
            spinner: false,
        }),
        NgProgressHttpModule,
        NgProgressRouterModule,
        BrowserAnimationsModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'tr-TR' },
        CoreSystemService,
        AppService,
        Configuration,
        MenuService,
        MenuDataService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
