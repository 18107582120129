import { Component, HostBinding, OnInit, ViewChild, HostListener } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { ScreenService, AppInfoService, AuthorizationService } from './shared/services';

import { CoreSystemService } from './pages/core-system/core-system.service';
import { AppService } from './app.service';
import { DxPopupComponent } from 'devextreme-angular';
import { CurrentProgressService } from './shared/components/current-progress/current-progress.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    year: number = new Date().getFullYear();
    logohalfLightImageUrl: string = '';
    logohalfDarkImageUrl: string = '';

    //Close all popups on ESC press
    @ViewChild(DxPopupComponent) popup: DxPopupComponent;
    @HostListener('document:keydown', ['$event'])
    onKeyDown(ev: KeyboardEvent) {
        if (ev.keyCode === 27 && this.popup) {
            this.popup.instance.hide();
        }
    }

    @HostBinding('class') get getClass() {
        return Object.keys(this.screen.sizes)
            .filter((cl) => this.screen.sizes[cl])
            .join(' ');
    }

    constructor(
        private coreSystem: CoreSystemService,
        public appService: AppService,
        private screen: ScreenService,
        public appInfo: AppInfoService,
        private currentProgressService: CurrentProgressService
    ) {}

    ngOnInit(): void {
        this.setThemePerCustomer();

        this.coreSystem.checkCredentials().then(
            () => {
                if (!this.currentProgressService.monitorStarted)
                    this.currentProgressService.startMonitorCurrentProgress();
            },
            () => {
                this.currentProgressService.stopMonitorCurrentProgress();
            }
        );

        //Add SiteID and UserName to Sentry tags
        if (localStorage.getItem('currentUsername'))
            Sentry.configureScope((scope) => {
                scope.setUser({
                    username: localStorage.getItem('currentUsername'),
                });
            });

        if (localStorage.getItem('currentSiteId'))
            Sentry.configureScope((scope) => {
                scope.setTag('SiteId', localStorage.getItem('currentSiteId'));
            });
    }

    isAutorized() {
        return this.coreSystem.isLoggedIn;
        // if (localStorage.getItem('authorization-version') && localStorage.getItem('currentUserToken') && localStorage.getItem('currentUserToken'))
        //   return true;
        // else
        //   return false;
    }

    setThemePerCustomer() {
        let baseUrl = 'assets/Branding/' + this.extractRootDomain(document.location.hostname);
        this.appService.getState().logoImageUrl = baseUrl + this.appService.getState().logoImageUrl;
        this.appService.getState().signinBackgroud = baseUrl + this.appService.getState().signinBackgroud;
        this.appService.getState().logohalfLightImageUrl = baseUrl + this.appService.getState().logohalfLightImageUrl;
        this.appService.getState().logohalfDarkImageUrl = baseUrl + this.appService.getState().logohalfDarkImageUrl;

        document.head.innerHTML +=
            "<link href='" +
            baseUrl +
            this.appService.getState().appletouchIcon +
            "' rel='apple-touch-icon' sizes='180x180'>";

        document.head.innerHTML +=
            "<link href='" +
            baseUrl +
            this.appService.getState().favicon32Url +
            "' type='image/png' rel='icon' sizes='32x32' >";

        document.head.innerHTML +=
            "<link href='" +
            baseUrl +
            this.appService.getState().favicon16Url +
            "' type='image/png' rel='icon' sizes='16x16'>";

        document.head.innerHTML +=
            "<link href='" + baseUrl + this.appService.getState().iconsmanifestUrl + "' rel='manifest'>";

        document.head.innerHTML +=
            "<link href='" + baseUrl + this.appService.getState().maskIconUrl + "' rel='mask-icon' color='#5bbad5'>";

        this.logohalfLightImageUrl = this.appService.getState().logohalfLightImageUrl;
        this.logohalfDarkImageUrl = this.appService.getState().logohalfDarkImageUrl;
    }

    extractRootDomain(hostname) {
        var domain = hostname,
            splitArr = domain.split('.'),
            arrLen = splitArr.length;

        //extracting the root domain here
        //if there is a subdomain
        if (arrLen > 2) {
            domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
            //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
            if (splitArr[arrLen - 1].length == 2 && splitArr[arrLen - 1].length == 2) {
                //this is using a ccTLD
                domain = splitArr[arrLen - 3] + '.' + domain;
            }
        }
        return domain;
    }
}
