import { Component, OnInit, NgModule, Input } from '@angular/core';
import { SideNavigationMenuModule, HeaderModule } from '../../shared/components';
import { ScreenService } from '../../shared/services';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { CommonModule } from '@angular/common';

import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { MenuDataService } from 'src/app/shared/services/menudataservice.service';

@Component({
    selector: 'app-side-nav-inner-toolbar',
    templateUrl: './side-nav-inner-toolbar.component.html',
    styleUrls: ['./side-nav-inner-toolbar.component.scss'],
})
export class SideNavInnerToolbarComponent implements OnInit {
    menuItems: any;
    selectedRoute = '';

    menuOpened: boolean;
    temporaryMenuOpened = false;

    @Input()
    title: string;

    menuMode = 'shrink';
    menuRevealMode = 'expand';
    minMenuSize = 0;
    shaderEnabled = false;
    menuSubscription: Subscription;

    constructor(private screen: ScreenService, private router: Router, private menuDataService: MenuDataService) {}

    ngOnInit() {
        this.menuSubscription = this.menuDataService.data.subscribe((item) => {
            if (localStorage.getItem('currentUserToken')) {
                //change menu
                this.menuItems = item;
                //this.selectedRoute = this.router.url.split('?')[0]
                // this.searchItems = this.menuService.getNotNestedMenu();
            }
        });

        this.menuOpened = this.screen.sizes['screen-large'];

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.selectedRoute = val.urlAfterRedirects.split('?')[0];
            }
        });

        this.screen.changed.subscribe(() => this.updateDrawer());

        this.updateDrawer();
    }

    updateDrawer() {
        const isXSmall = this.screen.sizes['screen-x-small'];
        const isLarge = this.screen.sizes['screen-large'];

        this.menuMode = isLarge ? 'shrink' : 'overlap';
        this.menuRevealMode = isXSmall ? 'slide' : 'expand';
        this.minMenuSize = isXSmall ? 0 : 60;
        this.shaderEnabled = !isLarge;
    }

    toggleMenu = (e) => {
        this.menuOpened = !this.menuOpened;
        e.event.stopPropagation();
    };

    get hideMenuAfterNavigation() {
        return this.menuMode === 'overlap' || this.temporaryMenuOpened;
    }

    get showMenuAfterClick() {
        return !this.menuOpened;
    }

    navigationChanged(event) {
        const path = event.itemData.path;
        const pointerEvent = event.event;

        if (path && this.menuOpened) {
            if (event.node.selected) {
                pointerEvent.preventDefault();
            } else {
                this.router.navigate([path]);
            }

            if (this.hideMenuAfterNavigation) {
                this.temporaryMenuOpened = false;
                this.menuOpened = false;
                pointerEvent.stopPropagation();
            }
        } else {
            pointerEvent.preventDefault();
        }
    }

    navigationClick() {
        if (this.showMenuAfterClick) {
            this.temporaryMenuOpened = true;
            this.menuOpened = true;
        }
    }
}

@NgModule({
    imports: [
        SideNavigationMenuModule,
        DxDrawerModule,
        HeaderModule,
        DxToolbarModule,
        DxScrollViewModule,
        CommonModule,
    ],
    exports: [SideNavInnerToolbarComponent],
    declarations: [SideNavInnerToolbarComponent],
})
export class SideNavInnerToolbarModule {}
