import { Component, OnInit, NgModule, ChangeDetectorRef } from '@angular/core';
import { DxButtonModule, DxPopoverModule, DevExtremeModule, DxListModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { CurrentProgressService } from './current-progress.service';

@Component({
    selector: 'app-current-progress',
    templateUrl: './current-progress.component.html',
    styleUrls: ['./current-progress.component.scss'],
})
export class CurrentProgressComponent implements OnInit {
    currentProgressListVisible: boolean;
    currentProgressList: any[] = [];
    currentProgressLastSeen: Date;
    currentUserName: string;

    constructor(public _currentProgressService: CurrentProgressService) {
        this.currentProgressListVisible = false;
    }

    ngOnInit() {
        this._currentProgressService.CurrentProgressListSubject.subscribe((data) => {
            this.currentProgressList = data;
        });

        if (this._currentProgressService.monitorStarted) {
            this._currentProgressService.getCurrentProgressListForFirstTime();
        }

        this.currentProgressLastSeen = new Date(Number(localStorage.getItem('currentProgressLastSeen')));
        if (!this.currentProgressLastSeen) {
            var date = new Date();
            this.currentProgressLastSeen = new Date(date.setDate(date.getDate() - 1));
        }

        this.currentUserName = localStorage.getItem('currentUsername');
    }

    toggleCurrentProgressList() {
        this.currentProgressListVisible = !this.currentProgressListVisible;
    }

    currentProgressPopOverOnHidden() {
        this.currentProgressLastSeen = new Date();
        localStorage.setItem('currentProgressLastSeen', this.currentProgressLastSeen.getTime().toString());
        this._currentProgressService.CurrentProgressShowNewLabel = false;
    }

    getclassName(messageType) {
        switch (messageType) {
            case 0:
                return 'fas fa-network-wired';
            case 1:
                return 'fas fa-file-invoice-dollar';
            case 2:
                return 'far fa-file-pdf';
            case 3:
                return 'far fa-credit-card';
            case 4:
                return 'fas fa-sms';
            case 5:
                return 'fas fa-file-excel';
        }
    }
}

@NgModule({
    imports: [CommonModule, DxButtonModule, DxPopoverModule, DxListModule],
    declarations: [CurrentProgressComponent],
    providers: [CurrentProgressService],
    exports: [CurrentProgressComponent],
})
export class CurrentProgressModule {}
