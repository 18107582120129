import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { SweetAlertOptions } from 'sweetalert2';

export enum AlertType {
    info,
    warning,
    error,
    success,
}

@Injectable()
export class AlertService {
    public swalNotificationWithTimerOptions: SweetAlertOptions = {
        title: '',
        text: '',
        allowOutsideClick: true,
        allowEscapeKey: true,
        confirmButtonText: 'Tamam',
        timer: 5000,
    };

    public swalErrorOptions: SweetAlertOptions = {
        title: '',
        icon: 'error',
        text: '',
        allowOutsideClick: true,
        allowEscapeKey: true,
        confirmButtonText: 'Tamam',
    };

    public swalInfoOptions: SweetAlertOptions = {
        title: '',
        icon: 'info',
        text: '',
        allowOutsideClick: true,
        allowEscapeKey: true,
        confirmButtonText: 'Tamam',
    };

    public swalSuccessOptions: SweetAlertOptions = {
        title: '',
        icon: 'success',
        text: '',
        allowOutsideClick: true,
        allowEscapeKey: true,
        confirmButtonText: 'Tamam',
    };

    public swalWarningOptions: SweetAlertOptions = {
        title: 'Dikkat!',
        icon: 'warning',
        text: '',
        allowOutsideClick: true,
        allowEscapeKey: true,
        confirmButtonText: 'Tamam',
    };

    public swalToastOptions: SweetAlertOptions = {
        title: '',
        icon: 'info',
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        // background: 'rgb(207, 247, 211)'
    };

    public swalConfirmOptions: SweetAlertOptions = {
        title: '',
        text: '',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Vazgeç',
        confirmButtonText: 'Evet',
        reverseButtons: true,
    };

    public swalLoadingOptions: SweetAlertOptions = {
        imageUrl: 'assets/img/Loading/Loading60.gif',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        title: 'Yükleniyor',
    };

    public swalHtmlOptions: SweetAlertOptions = {
        title: '',
        html: '',
        icon: 'info',
        confirmButtonText: 'Tamam',
        allowOutsideClick: false,
        allowEscapeKey: false,
    };

    constructor() {}

    showAlert(msgtime: number, msg: string, type: AlertType = AlertType.info, width: number = 400) {
        let alertObj = {
            message: msg,
            type: AlertType[type],
            displayTime: msgtime,
            // closeOnClick: true,
            height: 55,
            width: width,
        };
        notify(alertObj);
    }

    showAlertAndRefreshOnAction(msgtime: number, msg: string, type: AlertType = AlertType.info, width: number = 400) {
        let alertObj = {
            message: msg,
            type: type,
            displayTime: msgtime,
            closeOnClick: true,
            height: 55,
            width: width,
        };
        notify(alertObj);
        window.location.reload();
    }
}
