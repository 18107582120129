import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';

@Component({
    selector: 'datagrid-date-periods-filter',
    templateUrl: './datagrid-date-periods-filter.component.html',
})
export class DataGridDatePeriodsFilterComponent implements OnInit {
    @Input() dataGrid: DxDataGridComponent;
    @Input() columnName: string;
    @Input() labelText: string;
    @Input() selectedValue: any;
    @Output() selectedValueChangeEmitter = new EventEmitter<any>();
    selectOptions: any;

    ngOnInit() {
        this.selectOptions = [
            {
                key: '1',
                name: 'Bu Hafta',
            },
            {
                key: '2',
                name: 'Bu Ay',
            },
            {
                key: '3',
                name: 'Son 3 ay',
            },
            {
                key: '4',
                name: 'Son bir sene',
            },
            {
                key: '-1',
                name: 'Tamamı',
            },
        ];
    }

    selectChange(data) {
        let filterArray: any = [];
        let dataSource = this.dataGrid.instance.getDataSource();
        let currentFilterList = dataSource.filter();
        if (currentFilterList && currentFilterList.length > 0) {
            if (!(currentFilterList[0] instanceof Array))
                for (let i = 0; i < currentFilterList.length; i = i + 3) {
                    filterArray.push([currentFilterList[i], currentFilterList[i + 1], currentFilterList[i + 2]]);
                    filterArray.push('and');
                }
            else {
                currentFilterList.forEach((element) => {
                    if (element instanceof Array && element[0] != this.columnName) {
                        filterArray.push(element);
                        filterArray.push('and');
                    }
                });
            }
        }

        let startDate: Date;
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        switch (data.value) {
            case '1': {
                startDate = this.getMonday(today);
                break;
            }
            case '2': {
                startDate = this.getFirstDayOfMonth(today);
                break;
            }
            case '3': {
                startDate = this.getFirstDayOfThreeMonthago(today);
                break;
            }
            case '4': {
                startDate = this.getPreviousYear(today);
                break;
            }
        }

        // There is new filter to add previous filters
        if (startDate) {
            filterArray.push([this.columnName, '>=', startDate]);
            dataSource.filter(filterArray);
            dataSource.load();
        } else {
            // There is not new filter to add

            // There is previous filter to be applied
            if (filterArray.length > 0) {
                // Remove Last 'and' item
                filterArray.pop();
                dataSource.filter(filterArray);
                dataSource.load();
            } // There is not any previous filter so Remove all filters
            else this.dataGrid.instance.clearFilter();
        }

        this.selectedValue = data.value;
        this.selectedValueChangeEmitter.emit(this.selectedValue);
    }

    getMonday(date) {
        let day = date.getDay(),
            diff = date.getDate() - day + (day == 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    }

    getFirstDayOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    getFirstDayOfThreeMonthago(date) {
        return new Date(date.getFullYear(), date.getMonth() - 3, 1);
    }

    getFirstDayOfYear(date) {
        return new Date(date.getFullYear(), 0, 1);
    }

    getPreviousYear(date) {
        return new Date(date.getFullYear() - 1, date.getMonth(), 1);
    }
}
