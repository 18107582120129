import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '../../../shared/services/http.service';
import { SiteSetting } from '../site-Setting.model';
import { MeteringCollector } from './models/collector-model';
import { MeteringCommonAreaViewDto } from './models/meteringCommonAreaViewDto';
import { DocumentIssuanceNoteDto } from './models/documentIssuanceNoteDto';

export interface ISlaveView {
    RowNumber: any;
    SiteID: any;
    BuildingID: any;
    BuildingName: any;
    PlaceID: any;
    NumberOrName: any;
    NetArea: any;
    GrossArea: any;
    IsCommonArea: any;
    SubscriptionID: any;
    SubscriptionName: any;
    SlaveID: any;
    SlaveSerialNumber: any;
    Brand: any;
    Model: any;
    SlaveType: any;
    SlaveTypeID: any;
    SlaveIsOffline: any;
    SlaveIsActive: any;
    SlaveStartDate: any;
    SlaveEndDate: any;
    MeteringCollectorID: any;
    CollectorName: any;
    SlaveLastReadingValue: any;
    SlaveLastReadingFetchDate: any;
    SlaveInitialValue: any;
}
@Injectable()
export class MeteringSystemService {
    constructor(private httpService: HttpService) {}

    getSiteMetetingSetting() {
        let headers = new HttpHeaders();
        return this.httpService.httpGet('MeteringSystem/GetSiteMeteringSetting', headers, null, false, false);
    }

    getMeteringTemperatureRegions() {
        let headers = new HttpHeaders();
        return this.httpService.httpGet('MeteringSystem/GetMeteringTemperatureRegions', headers, null, false, false);
    }

    getSiteMeteringSlaveToplogy(): any {
        let headers = new HttpHeaders();
        let params = new HttpParams();

        return this.httpService.httpGet('MeteringSystem/GetSiteMeteringSlaveToplogy', headers, params, false, false);
    }

    getMeteringCollectors(): any {
        let headers = new HttpHeaders();
        let params = new HttpParams();

        return this.httpService.httpGet('MeteringSystem/GetMeteringCollectors', headers, params, false, false);
    }

    updateSlavesCollector(changeCollectorFormData): any {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('NewCollectorID', changeCollectorFormData.selectedCollector);

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost(
            'MeteringSystem/UpdateSlavesCollector',
            changeCollectorFormData.slaveList,
            headers,
            params,
            false,
            false
        );
    }

    updateSlavesActiveStatus(changeActiveStatusFormData): any {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('Active', changeActiveStatusFormData.active == 1 ? 'true' : 'false');

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost(
            'MeteringSystem/UpdateSlavesActiveStatus',
            changeActiveStatusFormData.slaveList,
            headers,
            params,
            false,
            false
        );
    }

    updateSlavesOnlineStatus(changeOnlineStatusFormData): any {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('Online', changeOnlineStatusFormData.online == 1 ? 'true' : 'false');

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost(
            'MeteringSystem/UpdateSlavesOnlineStatus',
            changeOnlineStatusFormData.slaveList,
            headers,
            params,
            false,
            false
        );
    }

    changeSlaveWithNewSlave(
        slaveID,
        newOrExistingSlave,
        slaveChangeDate,
        readingValueBeforeChange,
        initialReadingValue,
        makeSlavePassiveAfterChange
    ) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('SlaveToChangeID', slaveID);
        params = params.set('SlaveChangeDate', slaveChangeDate);
        params = params.set('ReadingValueBeforeChange', readingValueBeforeChange);
        params = params.set('InitialReadingValue', initialReadingValue);
        params = params.set('MakeSlavePassiveAfterChange', makeSlavePassiveAfterChange);

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost(
            'MeteringSystem/ChangeSlaveWithNewSlave',
            newOrExistingSlave,
            headers,
            params,
            false,
            false
        );
    }

    changeSlaveWithExistingSlave(
        slaveToChangeID,
        existingSlaveID,
        slaveChangeDate,
        fSReadingBeforeChange,
        fSInitialReadingValue,
        sSReadingBeforeChange,
        sSInitialReadingValue,
        makeSlavePassiveAfterChange
    ) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('FirstSlaveID', slaveToChangeID);
        params = params.set('SecondSlaveID', existingSlaveID);
        params = params.set('SlaveChangeDate', slaveChangeDate);
        params = params.set('FSReadingBeforeChange', fSReadingBeforeChange);
        params = params.set('FSInitialReadingValue', fSInitialReadingValue);
        params = params.set('SSReadingBeforeChange', sSReadingBeforeChange);
        params = params.set('SSInitialReadingValue', sSInitialReadingValue);
        params = params.set('MakeSlavePassiveAfterChange', makeSlavePassiveAfterChange);

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost(
            'MeteringSystem/ChangeSlaveWithExistingSlave',
            null,
            headers,
            params,
            false,
            false
        );
    }

    addNewSlave(newSlaveView, slaveChangeDate, initialValueForSecondSlave) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('SlaveChangeDate', slaveChangeDate);
        params = params.set('InitialReadingValue', initialValueForSecondSlave);

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost('MeteringSystem/AddNewSlave', newSlaveView, headers, params, false, false);
    }

    deleteSlave(slaveID, slaveChangeDate, readingValueBeforeChange, makeSlavePassiveAfterChange) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('SlaveID', slaveID);
        params = params.set('SlaveDeleteDate', slaveChangeDate);
        params = params.set('ReadingValueBeforeChange', readingValueBeforeChange);
        params = params.set('MakeSlavePassiveAfterChange', makeSlavePassiveAfterChange);

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost('MeteringSystem/DeleteSlave', null, headers, params, false, false);
    }

    getSlaveTypes(): any {
        let headers = new HttpHeaders();
        let params = new HttpParams();

        return this.httpService.httpGet('MeteringSystem/GetSlaveTypes', headers, params, false, false);
    }

    getSiteSlaveChangeHistories() {
        let headers = new HttpHeaders();
        let params = new HttpParams();
        return this.httpService.httpGet('MeteringSystem/GetSiteSlaveChangeHistories', headers, params, false, false);
    }

    revertSlaveChange(slaveChangeHistoryID: any) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('SlaveChangeHistoryID', slaveChangeHistoryID);
        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost('MeteringSystem/RevertSlaveChange', null, headers, params, false, false);
    }

    addOrUpdateMeteringSiteSetting(siteSetting: SiteSetting) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost(
            'MeteringSystem/AddOrUpdateMeteringSiteSetting',
            siteSetting,
            headers,
            null,
            false,
            false
        );
    }

    addOrUpdateMeteringCollector(collectorFormdate: MeteringCollector) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost(
            'MeteringSystem/AddOrUpdateMeteringCollector',
            collectorFormdate,
            headers,
            null,
            false,
            false
        );
    }

    deleteCollector(collector: MeteringCollector) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('CollectorID', collector.ID.toString());

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost('MeteringSystem/DeleteMeteringCollector', null, headers, params, false, false);
    }

    getSiteMeteringCommonAreas() {
        let headers = new HttpHeaders();
        let params = new HttpParams();
        return this.httpService.httpGet('MeteringSystem/GetSiteMeteringCommonAreas', headers, params, false, false);
    }

    getSiteBuildings() {
        let headers = new HttpHeaders();
        let params = new HttpParams();
        return this.httpService.httpGet('MeteringSystem/GetSiteBuildings', headers, params, false, false);
    }

    getSitePlaces() {
        let headers = new HttpHeaders();
        let params = new HttpParams();
        return this.httpService.httpGet('MeteringSystem/GetSitePlaces', headers, params, false, false);
    }

    addOrUpdateMeteringCommonArea(commonAreasFormdata: MeteringCommonAreaViewDto) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);
        commonAreasFormdata.Guid = '00000000-0000-0000-0000-000000000000';
        return this.httpService.httpPost(
            'MeteringSystem/AddOrUpdateMeteringCommonArea',
            commonAreasFormdata,
            headers,
            null,
            false,
            false
        );
    }

    deleteMeteringCommonArea(commonAreasFormdata: MeteringCommonAreaViewDto) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('MeteringCommonAreaID', commonAreasFormdata.ID.toString());

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost(
            'MeteringSystem/DeleteMeteringCommonArea',
            null,
            headers,
            params,
            false,
            false
        );
    }

    getMeteringAccounts(accountType: number) {
        let headers = new HttpHeaders();
        let params = new HttpParams();
        if (accountType != null) params = params.set('AccountType', accountType.toString());

        return this.httpService.httpGet('MeteringSystem/GetMeteringAccounts', headers, params, false, false);
    }

    getProjects() {
        let headers = new HttpHeaders();
        let params = new HttpParams();

        return this.httpService.httpGet('MeteringSystem/GetProjects', headers, params, false, false);
    }

    getSlaveImportShablon(): any {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPostJsonReturnFile(
            'MeteringSystem/GetSlaveImportShablon',
            null,
            headers,
            null,
            false,
            false
        );
    }

    importSlaveExcel(formData: FormData, importDate) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);
        headers.append('Accept', 'multipart/form-data');

        let params = new HttpParams().set('ImportDate', importDate.toLocaleString('en-US'));
        return this.httpService.httpPostFile(
            'MeteringSystem/ImportSlaveExcel',
            formData,
            headers,
            params,
            false,
            false
        );
    }

    getSiteIssunaceNotes() {
        let headers = new HttpHeaders();
        let params = new HttpParams();

        return this.httpService.httpGet('MeteringSystem/GetDocumentIssuanceNotes', headers, params, false, false);
    }

    addOrUpdateDocumentIssuanceNote(issuanceNotesFormdata: DocumentIssuanceNoteDto) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);
        return this.httpService.httpPost(
            'MeteringSystem/AddOrUpdateDocumentIssuanceNote',
            issuanceNotesFormdata,
            headers,
            null,
            false,
            false
        );
    }

    deleteDocumentIssuanceNote(issuanceNote: DocumentIssuanceNoteDto) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('DocumentIssuanceNoteID', issuanceNote.ID.toString());

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost(
            'MeteringSystem/DeleteDocumentIssuanceNote',
            null,
            headers,
            params,
            false,
            false
        );
    }

    updateDocumentIssuanceNoteOrder(documentIssuanceNoteID: number, step: string) {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);
        let params = new HttpParams().set('DocumentIssuanceNoteID', documentIssuanceNoteID.toString());
        params = params.set('Step', step);

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost(
            'MeteringSystem/UpdateDocumentIssuanceNoteOrder',
            null,
            headers,
            params,
            false,
            false
        );
    }
}
