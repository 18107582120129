import { Injectable } from '@angular/core';
import { AlertService, AlertType } from './alert.service';
import { environment } from '../../../environments/environment';

import swal from 'sweetalert2';

//Sentry
// import * as Sentry from "@sentry/browser";

// Sentry.init({
//   dsn: "https://c5434e459ce04abd9c32ff838d96c118@sentry.io/1530453",
//   environment: environment.name
// });

@Injectable()
export class ErrorHandlerService {
    constructor(private alertservice: AlertService) {}

    handleHttpErrorWithSW(error: any, title: string = '', message: string = '') {
        if (error != null) {
            // if(error.status && error.status != 422)
            //     Sentry.captureException(error.originalError || error);
            if (error.error instanceof ProgressEvent) {
                this.alertservice.swalErrorOptions.title = error.message;
                swal.fire(this.alertservice.swalErrorOptions);
            } else {
                if (error.error) {
                    this.alertservice.swalErrorOptions.title = error.error.Message;
                    swal.fire(this.alertservice.swalErrorOptions);
                }
            }
        } else {
            this.alertservice.swalErrorOptions.title = message;
            this.alertservice.swalErrorOptions.title = title;
            swal.fire(this.alertservice.swalErrorOptions);
            //Sentry.captureMessage(message);
        }
    }

    handleHttpErrorWithNotify(error: any, message: string = '', time: number = 5000) {
        //Sentry.captureException(error.originalError || error);
        if (error != null) {
            if (error.error instanceof ProgressEvent) {
                this.alertservice.showAlert(time, error.message, AlertType.error);
            } else {
                if (error.error) this.alertservice.showAlert(time, error.error.Message, AlertType.error);
            }
        } else {
            this.alertservice.showAlert(time, message, AlertType.error);
            //Sentry.captureMessage(message);
        }
    }
}
