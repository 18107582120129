import { Component, NgModule, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';

import { CoreSystemService } from '../../../pages/core-system/core-system.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { SiteChangerModule } from '../sitechanger/sitechanger.component';
import { CurrentProgressModule } from '../current-progress/current-progress.component';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from '../../services';
import { CurrentProgressService } from '../current-progress/current-progress.service';
import { MeteringSystemService } from 'src/app/pages/metering/system/meteringsystem.service';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Output()
    menuToggle = new EventEmitter<boolean>();

    @Input()
    menuToggleEnabled = false;

    @Input()
    title: string;

    @Input()
    logoUrl: string;

    siteChangerPopupVisible: boolean = false;
    selectedSiteName: string = 'Site Seçiniz';
    routerSubscription: Subscription;

    userMenuItems = [
        {
            text: 'Şifre Değiştir',
            icon: 'fas fa-user',
            onClick: () => {
                this.router.navigate(['core-system/authentication/change-my-password']);
            },
        },
        {
            text: 'Logout',
            icon: 'fas fa-power-off',
            onClick: () => {
                this.coreSystemService.logout().subscribe(
                    (data) => {
                        this.coreSystemService.loggedIn = false;
                        localStorage.clear();
                        this.currentProgressService.stopMonitorCurrentProgress();
                        this.router.navigate(['core-system/authentication/signin']);
                    },
                    (error) => {
                        this.errorhandlerservice.handleHttpErrorWithSW(error);
                    }
                );
            },
        },
    ];

    constructor(
        private coreSystemService: CoreSystemService,
        private errorhandlerservice: ErrorHandlerService,
        private router: Router,
        private httpService: HttpService,
        private currentProgressService: CurrentProgressService,
        private meteringSystemService: MeteringSystemService
    ) {
        // if sitename has been changed, chnaged on router changes event. (this is for working in multi window)
        this.routerSubscription = this.router.events.subscribe(
            (event) => {
                if (event instanceof NavigationStart) {
                    this.selectedSiteName = localStorage.getItem('currentSiteName');
                } else if (
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel ||
                    event instanceof NavigationError
                ) {
                }
            },
            (error: any) => {}
        );
    }

    ngOnInit(): void {
        this.getActiveSiteForUser();
    }

    toggleMenu = () => {
        this.menuToggle.emit();
    };

    openSiteSwitchDialog() {
        this.siteChangerPopupVisible = true;
    }

    getActiveSiteForUser() {
        let token = localStorage.getItem('currentUserToken');
        if (token) {
            let headers = new HttpHeaders();
            this.httpService.httpGet('Sites/GetCurrentSite', headers, null, true, false).subscribe(
                (data) => {
                    this.selectedSiteName = data.body.Name + ' [' + data.body.ID + ']';
                    localStorage.setItem('currentSiteName', this.selectedSiteName);
                },
                (error) => {
                    this.errorhandlerservice.handleHttpErrorWithSW(error);
                }
            );

            //get site metering setting
            this.meteringSystemService.getSiteMetetingSetting().subscribe(
                (data) => {
                    localStorage.setItem('siteMeteringSetting', JSON.stringify(data.body));
                },
                (error) => {
                    this.errorhandlerservice.handleHttpErrorWithSW(error);
                }
            );
        }
    }
}

@NgModule({
    imports: [CommonModule, DxButtonModule, UserPanelModule, DxToolbarModule, SiteChangerModule, CurrentProgressModule],
    declarations: [HeaderComponent],
    exports: [HeaderComponent],
    providers: [MeteringSystemService],
})
export class HeaderModule {}
