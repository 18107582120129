import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/';
import { MenuService } from './menu.service';

@Injectable()
export class MenuDataService {
    constructor(private menuService: MenuService) {}

    // Observable navItem source
    menu = this.menuService.getJsonMenuFromLocalStorage();

    private _menuDataSource = new BehaviorSubject<MenuService>(this.menu);

    // Observable navItem stream
    data = this._menuDataSource.asObservable();

    // service command
    updateMenu(data) {
        this._menuDataSource.next(data);
    }
}
