import { Component, Input } from '@angular/core';
import { DxDataGridComponent, DxTextBoxComponent } from 'devextreme-angular';

@Component({
    selector: 'datagrid-search-panel',
    templateUrl: './datagrid-search-panel.component.html',
})
export class DataGridSearchPanelComponent {
    @Input() dataGrid: DxDataGridComponent;
    @Input() searchInputWidth: string;

    SearchDataGrid(data) {
        this.dataGrid.instance.searchByText(data.value);
    }
}
