import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DevExtremeModule } from 'devextreme-angular';

import { CanVisitAfterLogin } from './shared/services/can-visit-after-login.service';
import { ForceUserToChangePassword } from './shared/services/force-user-change-password.service';

import { DashboardComponent } from './pages/dashboard/dashboard.component';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [CanVisitAfterLogin],
    },
    {
        path: '',
        children: [
            {
                path: 'core-system',
                loadChildren: () => import('./pages/core-system/core-system.module').then((m) => m.CoreSystemModule),
            },
        ],
    },
    {
        path: '',
        children: [
            {
                path: 'metering',
                loadChildren: () => import('./pages/metering/metering.module').then((m) => m.MeteringModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/dashboard',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true }), DevExtremeModule],
    providers: [CanVisitAfterLogin, ForceUserToChangePassword],
    exports: [RouterModule],
    declarations: [DashboardComponent],
})
export class AppRoutingModule {}
