import { Injectable } from '@angular/core';
import { Subject, Subscription, Observable, interval } from 'rxjs/';

import { HttpHeaders } from '@angular/common/http';
import { HttpService } from '../../services';
import { environment } from 'src/environments/environment';

@Injectable()
export class CurrentProgressService {
    monitorSubscription: Subscription;
    monitorStarted: boolean = false;
    siteColumns = ['SiteName', 'Message'];
    CurrentProgressShowNewLabel = true;
    allMessagesHasBeenSeen = false;

    constructor(private _httpService: HttpService) {}
    CurrentProgressListSubject = new Subject<any>();

    getCurrentProgressListForFirstTime() {
        this.getCurrentProgress().subscribe((data) => {
            this.updateCurrentProgressList(data.body);
        });
    }

    startMonitorCurrentProgress() {
        this.monitorStarted = true;
        //start monitor
        this.monitorSubscription = interval(environment.ProgressServiceInterval).subscribe(() => {
            this.getCurrentProgress().subscribe((data) => {
                this.updateCurrentProgressList(data.body);
            });
        });
    }

    stopMonitorCurrentProgress() {
        if (this.monitorSubscription) this.monitorSubscription.unsubscribe();
    }

    updateCurrentProgressList(data) {
        var currentProgressLastSeen = new Date(Number(localStorage.getItem('currentProgressLastSeen')));

        this.allMessagesHasBeenSeen = data.every((item) => {
            return new Date(item.Started) < currentProgressLastSeen;
        });

        var groupedData = data.reduce((r, { SiteName: name, ...object }) => {
            var temp = r.find((o) => o.key === name);
            if (!temp) r.push((temp = { key: name, items: [] }));
            temp.items.push({ ...object, SiteName: name });
            return r;
        }, []);

        this.CurrentProgressShowNewLabel = true;
        this.CurrentProgressListSubject.next(groupedData);
    }

    private getCurrentProgress() {
        let headers = new HttpHeaders();
        return this._httpService.httpGet('CurrentProgress/GetAll', headers, null, false, false);
    }
}
