import { Component, Input } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'datagrid-toggle-filters',
    templateUrl: './datagrid-toggle-filters.component.html',
})
export class DataGridToggleFiltersComponent {
    @Input() dataGrid: DxDataGridComponent;

    ToggleFilters(e) {
        let visible = !this.dataGrid.filterRow.visible;

        this.dataGrid.instance.option({
            filterRow: {
                visible: visible,
                applyFilter: 'auto',
            },
            headerFilter: {
                visible: visible,
            },
        });

        //repaint datagrid to triger OncontentReady event
        this.dataGrid.instance.repaint();
    }
}
