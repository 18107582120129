import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from '../../shared/services/http.service';
import { CookieService } from 'ngx-cookie';
import { map, first } from 'rxjs/operators';

export class User {
    constructor(
        public Username: string,
        public Password: string,
        public Platform: number,
        public RememberMe: boolean,
        public NewPassword: string = '',
        public NewPasswordRepeat: string = '',
        public VerifyCode: string = '',
        public RememberPc: boolean = false,
        public Captcha: string = ''
    ) {}
}

@Injectable()
export class CoreSystemService {
    public loggedIn = false;

    constructor(private httpService: HttpService, private cookieService: CookieService) {}

    get isLoggedIn() {
        return this.loggedIn;
    }

    // Logout user
    logout() {
        let token = localStorage.getItem('currentUserToken');
        let headers = new HttpHeaders().set('token', token);

        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.set('authorization-version', authVersion);

        //if (fingerprint != "") { headers.set('FingerPrint', fingerprint); }
        return this.httpService.httpPostTypetxt('Authentication/Logout', '', headers);
    }

    // lock user when Is Idle
    lock() {
        if (localStorage.getItem('currentUserToken')) {
            let token = localStorage.getItem('currentUserToken');
            let headers = new HttpHeaders().set('token', token);

            let authVersion = localStorage.getItem('authorization-version');
            headers = headers.set('authorization-version', authVersion);

            return this.httpService.httpPost('Authentication/Logout', '', headers);
        }
    }

    // Login User To System
    login(user, fingerprint, reCaptchaResponse) {
        let headers = new HttpHeaders().set('finger-print', fingerprint);
        if (reCaptchaResponse) headers = headers.set('recaptcha-response', reCaptchaResponse);

        let RememberPcCookie = this.cookieService.get('RememberPc-Cookie');
        if (RememberPcCookie) headers = headers.set('rememberpc-cookie', RememberPcCookie);

        return this.httpService.httpPost(
            'Authentication/Login',
            { Username: user.Username, Password: user.Password, Platform: 1, RememberMe: user.RememberMe },
            headers
        );
    }

    // Reset user password
    ChangeMyPassword(user) {
        let token = localStorage.getItem('currentUserToken');
        let authVersion = localStorage.getItem('authorization-version');

        let headers = new HttpHeaders().set('token', token);
        headers = headers.set('authorization-version', authVersion);

        return this.httpService.httpPost(
            'Authentication/ChangeMyPassword',
            { CurrentPassword: user.Password, NewPassword: user.NewPasswordRepeat },
            headers
        );
    }

    // Send Reset Email
    SendPasswordResetLink(user, reCaptchaResponse) {
        let headers = new HttpHeaders().set('recaptcha-response', reCaptchaResponse);
        return this.httpService.httpPost(
            `Authentication/SendPasswordResetLink/?username=${user.Username}`,
            null,
            headers
        );
    }

    // Send Password to change then login
    ResetMyPassword(user, resetToken) {
        let headers = new HttpHeaders();
        return this.httpService.httpPost(
            'Authentication/ResetMyPassword',
            { ResetToken: resetToken, NewPassword: user.NewPasswordRepeat },
            headers
        );
    }

    // Verify Reset Password Token
    VerifyPasswordResetLink(resetToken) {
        let headers = new HttpHeaders();
        return this.httpService.httpPost(
            `Authentication/VerifyPasswordResetLink/?resetToken=${resetToken}`,
            null,
            headers
        );
    }

    // Checking If User Is logged In or Not
    checkCredentials() {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('currentUserToken') === null) {
                reject('error');
            } else {
                this.keepSessionAlive().subscribe(
                    (data) => {
                        resolve('ok');
                    },
                    (error) => {
                        localStorage.clear();
                        reject('error');
                    }
                );
            }
        });
    }

    keepSessionAlive() {
        let headers = new HttpHeaders();
        return this.httpService.httpGetTypetxt('Authentication/Refresh', headers, false, false);
    }

    // Verify SMS Code
    verifySMSCode(user: User) {
        let attempGuid = localStorage.getItem('LAG');
        let headers = new HttpHeaders().set('attempt-guid', attempGuid);

        return this.httpService.httpPost(
            'Authentication/ValidateTwoFactorVerification',
            { VerifyCode: user.VerifyCode, RememberPc: user.RememberPc },
            headers
        );
    }

    getnextSMSSendTime() {
        let attempGuid = localStorage.getItem('LAG');
        let headers = new HttpHeaders().set('attempt-guid', attempGuid);
        return this.httpService.httpPost('Authentication/GetNextSmsSendTime', '', headers);
    }

    SendSMS() {
        let attempGuid = localStorage.getItem('LAG');
        let headers = new HttpHeaders().set('attempt-guid', attempGuid);
        return this.httpService.httpPost('Authentication/SendSms', '', headers);
    }
}
