import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DevExtremeModule } from 'devextreme-angular';
import 'devextreme/integration/jquery';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ClipboardModule } from 'ngx-clipboard';

import { ScreenService, AppInfoService } from './services';

import { FooterModule, FooterComponent, HeaderModule, UserPanelModule, SideNavigationMenuModule } from './components';
import {
    DataGridSearchPanelComponent,
    DataGridExportButtonComponent,
    DataGridDropdownFilterComponent,
    DataGridDatePeriodsFilterComponent,
    DataGridToggleFiltersComponent,
} from './components/';
import { ElmaButtonGroupComponent, SiteChangerModule, CurrentProgressModule } from './components';

import {
    HttpService,
    ErrorHandlerService,
    AlertService,
    AuthorizationService,
    EnumsService,
    SentryErrorHandler,
    MathService,
} from './services';

import { MaterialModule } from './components/material.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FooterModule,
        HeaderModule,
        UserPanelModule,
        SideNavigationMenuModule,
        DevExtremeModule,
        MaterialModule,
        SweetAlert2Module.forRoot(),
        ClipboardModule,
        FlexLayoutModule,
        SiteChangerModule,
        CurrentProgressModule,
    ],
    declarations: [
        DataGridSearchPanelComponent,
        DataGridExportButtonComponent,
        DataGridDropdownFilterComponent,
        DataGridDatePeriodsFilterComponent,
        DataGridToggleFiltersComponent,
        ElmaButtonGroupComponent,
    ],
    providers: [
        ScreenService,
        AppInfoService,
        HttpService,
        AuthorizationService,
        ErrorHandlerService,
        AlertService,
        DatePipe,
        EnumsService,
        MathService,
        { provide: ErrorHandler, useClass: SentryErrorHandler },
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        DevExtremeModule,
        MaterialModule,
        FlexLayoutModule,
        ClipboardModule,
        DataGridSearchPanelComponent,
        DataGridExportButtonComponent,
        DataGridDropdownFilterComponent,
        DataGridDatePeriodsFilterComponent,
        DataGridToggleFiltersComponent,
        ElmaButtonGroupComponent,
        FooterComponent,
    ],
})
export class SharedModule {}
