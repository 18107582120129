import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlertService } from './alert.service';
import swal from 'sweetalert2';
import { CoreSystemService } from 'src/app/pages/core-system/core-system.service';

@Injectable()
export class CanVisitAfterLogin implements CanActivate {
    constructor(private router: Router, private authService: CoreSystemService, private alertservice: AlertService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.checkCredentials().then(
            () => {
                this.authService.loggedIn = true;
                if (localStorage.getItem('change-password'))
                    if (
                        localStorage.getItem('change-password') == 'True' &&
                        state.url != '/core-system/authentication/hange-my-password'
                    ) {
                        this.alertservice.swalInfoOptions.title =
                            'Şifrenizin kulanma süresi bitmiştir. Şifreniz değiştiriniz';
                        swal.fire(this.alertservice.swalInfoOptions).then(() => {
                            this.router.navigate(['/core-system/authentication/change-my-password']);
                        });
                    }
                return true;
            },
            () => {
                this.authService.loggedIn = false;
                this.router.navigate(['/core-system/authentication/signin'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        );
    }
}
