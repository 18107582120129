import { Component, OnInit, Input, Output, EventEmitter, NgModule, AfterViewInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as Sentry from '@sentry/browser';

import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HttpService, ErrorHandlerService } from '../../services';
import {
    DxPopupModule,
    DxScrollViewModule,
    DxFormModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxValidatorModule,
    DxButtonModule,
    DxAutocompleteModule,
    DxSelectBoxComponent,
} from 'devextreme-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { MeteringSystemService } from 'src/app/pages/metering/system/meteringsystem.service';

@Component({
    selector: 'app-sitechanger',
    templateUrl: './sitechanger.component.html',
    styleUrls: ['./sitechanger.component.scss'],
})
export class SiteChangerComponent implements OnInit {
    @Input() siteChangerPopupVisible: boolean;
    @Output() selectedSiteNameEmitter = new EventEmitter<any>();
    @Output() siteChangerPopupVisibleEmitter = new EventEmitter<any>();

    sites: any;
    siteColumns = ['ID', 'Name', 'ManagementName'];
    siteChangerFormData = { selectSiteId: null, selecteSiteName: '', SetDefualt: false };

    @ViewChild('siteselector') siteselector: DxSelectBoxComponent;

    constructor(
        private httpService: HttpService,
        private errorhandlerservice: ErrorHandlerService,
        private router: Router,
        private meteringSystemService: MeteringSystemService
    ) {}

    ngOnInit() {
        this.loadSitesList();
    }

    loadSitesList() {
        let headers = new HttpHeaders();
        this.httpService.httpGet('Sites/GetSites', headers, null, false, false).subscribe(
            (data) => {
                this.sites = data.body;
            },
            (error) => {
                this.errorhandlerservice.handleHttpErrorWithSW(error);
            }
        );
    }

    selcetedSiteChange(e) {
        let selectedSite = e.selectedItem;
        if (selectedSite) {
            this.siteChangerFormData.selecteSiteName = selectedSite.Name;
        } else {
            this.siteChangerFormData.selecteSiteName = '';
        }
    }

    confirmSelection() {
        if (this.siteChangerFormData.selectSiteId != -1) {
            let token = localStorage.getItem('currentUserToken');
            let headers = new HttpHeaders().set('token', token);

            let authVersion = localStorage.getItem('authorization-version');
            headers = headers.set('authorization-version', authVersion);

            this.httpService
                .httpPostTypetxt(
                    'Sites/ChangeActiveSite',
                    { SiteId: this.siteChangerFormData.selectSiteId, SetDefualt: this.siteChangerFormData.SetDefualt },
                    headers,
                    true,
                    false
                )
                .subscribe(
                    (data) => {
                        this.siteChangerPopupVisible = false;
                        localStorage.setItem(
                            'currentSiteName',
                            this.siteChangerFormData.selecteSiteName +
                                ' [' +
                                this.siteChangerFormData.selectSiteId +
                                ']'
                        );
                        localStorage.setItem('currentSiteId', this.siteChangerFormData.selectSiteId);

                        //send selected site name to parent
                        this.selectedSiteNameEmitter.emit(
                            this.siteChangerFormData.selecteSiteName +
                                ' [' +
                                this.siteChangerFormData.selectSiteId +
                                ']'
                        );

                        //send popup visble to parent
                        this.siteChangerPopupVisibleEmitter.emit(this.siteChangerPopupVisible);

                        //reset form data
                        this.siteChangerFormData = { selectSiteId: null, selecteSiteName: '', SetDefualt: false };

                        //Add SiteID to Sentry tags
                        Sentry.configureScope((scope) => {
                            scope.setTag('SiteId', localStorage.getItem('currentSiteId'));
                        });

                        //get site metering setting
                        let headers = new HttpHeaders();
                        this.meteringSystemService.getSiteMetetingSetting().subscribe(
                            (data) => {
                                localStorage.setItem('siteMeteringSetting', JSON.stringify(data.body));
                            },
                            (error) => {
                                this.errorhandlerservice.handleHttpErrorWithSW(error);
                            }
                        );

                        this.router.navigate(['/dashboard']);
                    },
                    (error: HttpErrorResponse) => {
                        this.errorhandlerservice.handleHttpErrorWithSW(error);
                    }
                );
        }
    }

    closeSiteChangerPopup() {
        this.siteChangerPopupVisible = false;
        this.siteChangerPopupVisibleEmitter.emit(this.siteChangerPopupVisible);
    }

    popUpOnShow(e) {
        this.siteselector.instance.repaint();
        this.siteselector.instance.focus();
        this.siteselector.instance.open();
    }
}

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        DxPopupModule,
        DxScrollViewModule,
        DxFormModule,
        DxSelectBoxModule,
        DxCheckBoxModule,
        DxValidatorModule,
        DxButtonModule,
        FlexLayoutModule,
        DxAutocompleteModule,
    ],
    declarations: [SiteChangerComponent],
    exports: [SiteChangerComponent],
})
export class SiteChangerModule {}
