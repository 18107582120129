import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

export interface IElmaGroupButton {
    Action: string;
    Text: string;
    TopElement: boolean;
    IconClass?: string;
    disabled?: boolean;
    AddDivider?: boolean;
}

@Component({
    selector: 'elma-button-group',
    templateUrl: './button-group.component.html',
    styleUrls: ['./button-group.component.scss'],
})
export class ElmaButtonGroupComponent implements OnInit {
    @ViewChild(MatMenuTrigger)
    private menuTrigger: MatMenuTrigger;

    //Button Group Array
    @Input() groupButtonItems: Array<IElmaGroupButton>;

    //Default Button Action
    @Input() defaultButtonAction: string;

    //Grouped Buttons Type : Accepted Values: 'back' | 'danger' | 'default' | 'normal' | 'success'
    @Input() buttonsType: string = 'normal';

    //Seprated Buttons Type : Accepted Values: 'back' | 'danger' | 'default' | 'normal' | 'success'
    @Input() separatedButtonsType: string = 'normal';

    //true if want seprated items
    @Input() separatedTopItems: boolean = false;

    @Output() callEvent = new EventEmitter<number>();

    //Change detection for refreshing component
    changeDetection: ChangeDetectionStrategy.OnPush;

    ngOnInit() {}

    Callparent(item) {
        this.callEvent.next(item.Action);
    }

    OpenMenu() {
        // var element = document.getElementById('submenutrigge');
        // var bottom = element.getBoundingClientRect().bottom + window.scrollY;
        // var top = element.getBoundingClientRect().top + window.scrollX;

        var menu = document.getElementById('menuBtn');

        menu.style.display = '';
        menu.style.position = 'absolute';
        if (this.separatedTopItems) menu.style.marginTop = '2px';
        else menu.style.marginTop = '22px';

        this.menuTrigger.openMenu();
    }

    onMenuClosed(): void {
        var menu = document.getElementById('menuBtn');
        if (menu) {
            menu.style.display = 'none';
        }
    }

    subItemsCount() {
        return this.groupButtonItems.filter((item) => item.TopElement == false).length;
    }

    showSubmenuForSeparatedMenu() {
        let submenu = this.groupButtonItems.filter((item) => item.TopElement == false);

        if (submenu.length == 0) return false;
        else if (submenu.length == 1 && submenu[0].Action == this.defaultButtonAction) return false;
        else return true;
    }

    showSubmenuForGroupedMenu() {
        if (this.groupButtonItems.length == 1) return false;
        else return true;
    }

    checkIfAddItemTopToTopItems(item) {
        if (this.groupButtonItems.length == 1) {
            return true;
        } else {
            if (item.TopElement) {
                if (item.Action != this.defaultButtonAction) return true;
                else {
                    if (this.subItemsCount() == 0) return true;
                    else return false;
                }
            } else {
                if (this.subItemsCount() == 1) return true;
                else return false;
            }
        }
        // (item.TopElement && item.Action != defaultButtonAction)
        // || (item.Action == defaultButtonAction && (groupButtonItems.length == 1 || subItemsCount() == 0))
    }
}
