import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';

@Component({
    selector: 'datagrid-dropdown-filter',
    templateUrl: './datagrid-dropdown-filter.component.html',
})
export class DataGridDropdownFilterComponent {
    @Input() dataGrid: DxDataGridComponent;
    @Input() columnName: string;
    @Input() selectOptions: any;
    @Input() valueExpression: string;
    @Input() displayExpression: string;
    @Input() labelText: string;
    @Input() compareOprator: string;
    @Input() selectedValue: any;
    @Output() selectedValueChangeEmitter = new EventEmitter<any>();
    defualtValue: any;

    selectChange(data) {
        let dataSource = this.dataGrid.instance.getDataSource();
        let filterArray: any = [];
        let currentFilterList = dataSource.filter();
        if (currentFilterList && currentFilterList.length > 0) {
            if (!(currentFilterList[0] instanceof Array))
                for (let i = 0; i < currentFilterList.length; i = i + 3) {
                    filterArray.push([currentFilterList[i], currentFilterList[i + 1], currentFilterList[i + 2]]);
                    filterArray.push('and');
                }
            else {
                currentFilterList.forEach((element) => {
                    if (element instanceof Array && element[0] != this.columnName) {
                        filterArray.push(element);
                        filterArray.push('and');
                    }
                });
            }
        }

        if (data.value == 'Tümü') {
            // There is previous filter to be applied
            if (filterArray.length > 0) {
                // Remove Last 'and' item
                filterArray.pop();
                dataSource.filter(filterArray);
                dataSource.load();
            } // There is not any previous filter so Remove all filters
            else this.dataGrid.instance.clearFilter();
        } else {
            filterArray.push([this.columnName, this.compareOprator, data.value]);
            dataSource.filter(filterArray);
            dataSource.load();
        }

        this.selectedValue = data.value;
        this.selectedValueChangeEmitter.emit(this.selectedValue);
    }
}
