import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
var semver = require('semver');

export class Menu {
    constructor(
        public ID: number,
        public text: string,
        public title: string,
        public icon: string,
        public path: string,
        public notifyContent: string,
        public notifyClass: string,
        public items: Submenu[]
    ) {}
}

export class Submenu {
    constructor(
        public ID: number,
        public text: string,
        public title: string,
        public path: string,
        public notifyContent: string,
        public notifyClass: string
    ) {}
}

export class SearchResultmenu {
    constructor(public text: string, public title: string, public path: string) {}
}

@Injectable()
export class MenuService {
    constructor(public cookieService: CookieService) {}

    getJsonMenuFromLocalStorage() {
        if (localStorage.getItem('currentUserMenus')) {
            var localStorageMenu = JSON.parse(localStorage.getItem('currentUserMenus'));
            // var localStorageMenu = <Menu[]>JSON.parse(localStorage.getItem('currentUserMenus'));
            let clientversion = this.cookieService.get('LastVisitedVersion');
            let serverVersion = localStorage.getItem('application-version');

            for (let mItem of localStorageMenu) {
                //For Adding Any class to Menu Items
                // if (mItem.title == "Yeni Özellikler") {
                //     mItem.notifyContent = "yeni";
                //     mItem.notifyClass = "bg-primary label";
                // }
                if (mItem.items != null) {
                    for (let item of mItem.items) {
                        //For Adding Any class to Sub Menu Items
                        if (item.title == 'Yeni Özellikler') {
                            if (!semver.valid(clientversion)) {
                                item.notifyContent = 'yeni';
                                item.notifyClass = 'bg-primary label';
                            } else if (semver.gt(serverVersion, clientversion)) {
                                item.notifyContent = 'yeni';
                                item.notifyClass = 'bg-primary label';
                            }
                        }
                    }
                }
            }
            return localStorageMenu;
        }
    }

    //For Search Menu (Jump Menu)
    getNotNestedMenu() {
        if (localStorage.getItem('currentUserMenus')) {
            var localStorageMenu = <Menu[]>JSON.parse(localStorage.getItem('currentUserMenus'));
            let notNestedMenuItems = new Array<SearchResultmenu>();

            for (let mItem of localStorageMenu) {
                let menu = new SearchResultmenu('', '', '');
                menu.text = mItem.text;
                menu.title = mItem.title;
                menu.path = mItem.path != null ? mItem.path : '';
                notNestedMenuItems.push(menu);
                if (mItem.items != null) {
                    for (let sub of mItem.items) {
                        let submenu = new SearchResultmenu('', '', '');
                        submenu.text = sub.text;
                        submenu.title = sub.title;
                        submenu.path = sub.path != null ? sub.path : '';
                        notNestedMenuItems.push(submenu);
                    }
                }
            }
            return notNestedMenuItems;
        } else {
            console.log('Error getting menu');
        }
    }

    // //creating nest list of menu items
    // getNestedChildren(arr, ParentID) {
    //     var out = []
    //     for (var i in arr) {
    //         if (arr[i].ParentID == ParentID) {
    //             var Item = new Menu(
    //                 arr[i].DisplayText, //name
    //                 arr[i].DisplayText, //title
    //                 "fa-magic", //faIcon
    //                 arr[i].link, //link
    //                 "",  //notifyContent
    //                 "",  //notifyClass
    //                 null //sub
    //             )
    //             var children = this.getNestedChildren(arr, arr[i].ID)
    //             if (children.length) {
    //                 // arr[i].children = children
    //                 Item.sub = children
    //             }
    //             out.push(Item)
    //         }
    //     }
    //     return out
    // }
}
