import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    constructor(private appService: AppService, private titleService: Title) {
        this.appService.getState().topnavTitle = 'Özet';
        this.titleService.setTitle('Özet');
    }

    ngOnInit() {}
}
