import { Injectable } from '@angular/core';
import { Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from './alert.service';
import swal from 'sweetalert2';
import { ChangeMyPasswordComponent } from 'src/app/pages/core-system/authentication/change-my-password/change-my-password.component';

@Injectable()
export class ForceUserToChangePassword implements CanDeactivate<ChangeMyPasswordComponent> {
    constructor(private alertservice: AlertService) {}

    canDeactivate(
        component: ChangeMyPasswordComponent,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        if (localStorage.getItem('change-password') == 'True') {
            this.alertservice.swalInfoOptions.title = 'Şifreniz değiştirmeden, sayfaye terk edemezsiniz';
            swal.fire(this.alertservice.swalInfoOptions);
            return false;
        }
        return true;
    }
}
