import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

import { Configuration } from '../../app.constants';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ErrorHandlerService } from '../services/error-handler.service';
import { MenuDataService } from './menudataservice.service';
import { MenuService } from './menu.service';

@Injectable()
export class HttpService {
    @BlockUI() blockUI: NgBlockUI;

    constructor(
        private http: HttpClient,
        private configuration: Configuration,
        private errorhandlerservice: ErrorHandlerService,
        private menuDataService: MenuDataService,
        private menuService: MenuService
    ) {}

    public httpPost(
        url: string,
        body: any,
        headers: HttpHeaders,
        params: HttpParams = null,
        willBlock: Boolean = true,
        loadingBar: Boolean = true
    ): Observable<any> {
        if (willBlock) {
            this.blockUI.start(this.configuration.waitingMsg);
        }
        headers = headers.set('Content-Type', 'application/json');
        if (!loadingBar) {
            headers = headers.append('ignoreProgressBar', '');
        }
        return this.http
            .post(this.configuration.ServerWithApiUrl + url, body, {
                headers: headers,
                params: params,
                withCredentials: true,
                observe: 'response',
            })
            .pipe(
                map((response) => {
                    //Check If Authorization Version has been changed Then Load all Pages,APIs, Menu again
                    if (localStorage.getItem('authorization-version') && localStorage.getItem('currentUserToken')) {
                        let serverAuthVersion = response.headers.get('authorization-version');
                        if (serverAuthVersion != localStorage.getItem('authorization-version')) {
                            let token = localStorage.getItem('currentUserToken');

                            //Get user Apis list and Page list and Menu list then  store in localstorage
                            this.getApisMenusPagesFromApi(token, serverAuthVersion);
                        }
                    }
                    return this.parseResponse(response, willBlock, loadingBar);
                })
            )
            .pipe(catchError((err) => this.handleError(err, willBlock, loadingBar)));
    }

    public httpPostJsonReturnFile(
        url: string,
        body: any,
        headers: HttpHeaders,
        params: HttpParams = null,
        willBlock: Boolean = true,
        loadingBar: Boolean = true
    ): Observable<any> {
        if (willBlock) {
            this.blockUI.start(this.configuration.waitingMsg);
        }
        headers = headers.set('Content-Type', 'application/json');
        if (!loadingBar) {
            headers = headers.append('ignoreProgressBar', '');
        }
        return this.http
            .post(this.configuration.ServerWithApiUrl + url, body, {
                headers: headers,
                params: params,
                withCredentials: true,
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(
                map((response) => {
                    //Check If Authorization Version has been changed Then Load all Pages,APIs, Menu again
                    if (localStorage.getItem('authorization-version') && localStorage.getItem('currentUserToken')) {
                        let serverAuthVersion = response.headers.get('authorization-version');
                        if (serverAuthVersion != localStorage.getItem('authorization-version')) {
                            let token = localStorage.getItem('currentUserToken');

                            //Get user Apis list and Page list and Menu list then  store in localstorage
                            this.getApisMenusPagesFromApi(token, serverAuthVersion);
                        }
                    }
                    return response;
                })
            )
            .pipe(catchError((err) => this.handleError(err, willBlock, loadingBar)));
    }

    public httpPostFileReturnJson(
        url: string,
        body: any,
        headers: HttpHeaders,
        params: HttpParams = null,
        willBlock: Boolean = true,
        loadingBar: Boolean = true
    ): Observable<any> {
        if (willBlock) {
            this.blockUI.start(this.configuration.waitingMsg);
        }
        return this.http
            .post(this.configuration.ServerWithApiUrl + url, body, {
                headers: headers,
                params: params,
                withCredentials: true,
                observe: 'response',
            })
            .pipe(
                map((response) => {
                    if (localStorage.getItem('Authorization-Version') && localStorage.getItem('currentUserToken')) {
                        let serverAuthVersion = response.headers.get('authorization-version');
                        if (serverAuthVersion != localStorage.getItem('authorization-version')) {
                            let token = localStorage.getItem('currentUserToken');
                            this.getApisMenusPagesFromApi(token, serverAuthVersion);
                        }
                    }
                    return this.parseResponse(response, willBlock, loadingBar);
                })
            )
            .pipe(catchError((err) => this.handleError(err, willBlock, loadingBar)));
    }

    public httpPostFile(
        url: string,
        body: any,
        headers: HttpHeaders,
        params: HttpParams = null,
        willBlock: Boolean = true,
        loadingBar: Boolean = true
    ): Observable<any> {
        if (willBlock) {
            this.blockUI.start(this.configuration.waitingMsg);
        }

        return this.http
            .post(this.configuration.ServerWithApiUrl + url, body, {
                headers: headers,
                params: params,
                withCredentials: true,
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(
                map((response) => {
                    //Check If Authorization Version has been changed Then Load all Pages,APIs, Menu again
                    if (localStorage.getItem('Authorization-Version') && localStorage.getItem('currentUserToken')) {
                        let serverAuthVersion = response.headers.get('authorization-version');
                        if (serverAuthVersion != localStorage.getItem('authorization-version')) {
                            let token = localStorage.getItem('currentUserToken');

                            //Get user Apis list and Page list and Menu list then  store in localstorage
                            this.getApisMenusPagesFromApi(token, serverAuthVersion);
                        }
                    }
                    return response;
                })
            )
            .pipe(catchError((err) => this.handleError(err, willBlock, loadingBar)));
    }

    public httpPostTypetxt(
        url: string,
        object: any,
        headers: HttpHeaders,
        willBlock: Boolean = true,
        loadingBar: Boolean = true
    ): Observable<any> {
        if (willBlock) {
            this.blockUI.start(this.configuration.waitingMsg);
        }

        headers = headers.set('Content-Type', 'application/json');

        if (!loadingBar) {
            headers = headers.append('ignoreProgressBar', '');
        }

        return this.http
            .post(this.configuration.ServerWithApiUrl + url, object, {
                headers: headers,
                withCredentials: true,
                observe: 'response',
                responseType: 'text',
            })
            .pipe(
                map((response) => {
                    //Check If Authorization Version has been changed Then Load all Pages,APIs, Menu again
                    if (localStorage.getItem('Authorization-Version') && localStorage.getItem('currentUserToken')) {
                        let serverAuthVersion = response.headers.get('authorization-version');
                        if (serverAuthVersion != localStorage.getItem('authorization-version')) {
                            let token = localStorage.getItem('currentUserToken');

                            //Get user Apis list and Page list and Menu list then  store in localstorage
                            this.getApisMenusPagesFromApi(token, serverAuthVersion);
                        }
                    }
                    return this.parseResponse(response, willBlock, loadingBar);
                })
            )
            .pipe(catchError((err) => this.handleError(err, willBlock, loadingBar)));
    }

    public httpPostWithoutToken(
        url: string,
        object: any,
        fingerprint: string = '',
        reCaptchaResponse: string = '',
        willBlock: Boolean = true,
        loadingBar: Boolean = true
    ): Observable<any> {
        if (willBlock) {
            this.blockUI.start(this.configuration.waitingMsg);
        }

        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        if (fingerprint != '') {
            headers = headers.append('finger-print', fingerprint);
        }
        if (reCaptchaResponse != null) {
            headers = headers.append('recaptcha-response', reCaptchaResponse);
        }

        if (!loadingBar) {
            headers = headers.append('ignoreProgressBar', '');
        }

        return this.http
            .post(this.configuration.ServerWithApiUrl + url, object, { headers: headers, observe: 'response' })
            .pipe(map((response) => this.parseResponse(response, willBlock, loadingBar)))
            .pipe(catchError((err) => this.handleError(err, willBlock, loadingBar)));
    }

    public httpPut(
        url: string,
        object: any,
        headers: HttpHeaders,
        params: HttpParams,
        willBlock: Boolean = true,
        loadingBar: Boolean = true
    ): Observable<any> {
        if (willBlock) {
            this.blockUI.start(this.configuration.waitingMsg);
        }

        let token = localStorage.getItem('currentUserToken');
        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.append('token', token);
        headers = headers.append('authorization-version', authVersion);
        headers = headers.set('Content-Type', 'application/json');

        if (!loadingBar) {
            headers = headers.append('ignoreProgressBar', '');
        }

        return this.http
            .put(this.configuration.ServerWithApiUrl + url, object, {
                headers: headers,
                params: params,
                withCredentials: true,
                observe: 'response',
            })
            .pipe(
                map((response) => {
                    //Check If Authorization Version has been changed Then Load all Pages,APIs, Menu again
                    if (localStorage.getItem('Authorization-Version') && localStorage.getItem('currentUserToken')) {
                        let serverAuthVersion = response.headers.get('authorization-version');
                        if (serverAuthVersion != localStorage.getItem('authorization-version')) {
                            let token = localStorage.getItem('currentUserToken');

                            //Get user Apis list and Page list and Menu list then  store in localstorage
                            this.getApisMenusPagesFromApi(token, serverAuthVersion);
                        }
                    }
                    return this.parseResponse(response, willBlock, loadingBar);
                })
            )
            .pipe(catchError((err) => this.handleError(err, willBlock, loadingBar)));
    }

    public httpGet(
        url: string,
        headers: HttpHeaders,
        params: HttpParams = null,
        willBlock: Boolean = true,
        loadingBar: Boolean = true
    ): Observable<any> {
        if (willBlock) {
            this.blockUI.start(this.configuration.waitingMsg);
        }

        let token = localStorage.getItem('currentUserToken');
        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.append('token', token);
        headers = headers.append('authorization-version', authVersion);
        headers = headers.append('Content-Type', 'application/json');

        if (!loadingBar) {
            headers = headers.append('ignoreProgressBar', '');
        }

        return this.http
            .get(this.configuration.ServerWithApiUrl + url, { headers: headers, params: params, observe: 'response' })
            .pipe(
                map((response) => {
                    //Check If Authorization Version has been changed Then Load all Pages,APIs, Menu again
                    if (localStorage.getItem('Authorization-Version') && localStorage.getItem('currentUserToken')) {
                        let serverAuthVersion = response.headers.get('authorization-version');
                        if (serverAuthVersion != localStorage.getItem('authorization-version')) {
                            let token = localStorage.getItem('currentUserToken');

                            //Get user Apis list and Page list and Menu list then  store in localstorage
                            this.getApisMenusPagesFromApi(token, serverAuthVersion);
                        }
                    }
                    return this.parseResponse(response, willBlock, loadingBar);
                })
            )
            .pipe(catchError((err) => this.handleError(err, willBlock, loadingBar)));
    }

    public httpGetTypetxt(
        url: string,
        headers: HttpHeaders,
        willBlock: Boolean = true,
        loadingBar: Boolean = true
    ): Observable<any> {
        if (willBlock) {
            this.blockUI.start(this.configuration.waitingMsg);
        }

        let token = localStorage.getItem('currentUserToken');
        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.append('token', token);
        headers = headers.append('authorization-version', authVersion);
        headers = headers.append('Content-Type', 'application/json');

        return this.http
            .get(this.configuration.ServerWithApiUrl + url, {
                headers: headers,
                observe: 'response',
                responseType: 'text',
            })
            .pipe(
                map((response) => {
                    //Check If Authorization Version has been changed Then Load all Pages,APIs, Menu again
                    if (localStorage.getItem('authorization-version') && localStorage.getItem('currentUserToken')) {
                        let serverAuthVersion = response.headers.get('authorization-version');
                        if (serverAuthVersion != localStorage.getItem('authorization-version')) {
                            let token = localStorage.getItem('currentUserToken');

                            //Get user Apis list and Page list and Menu list then  store in localstorage
                            this.getApisMenusPagesFromApi(token, serverAuthVersion);
                        }
                    }
                    return this.parseResponse(response, willBlock, loadingBar);
                })
            )
            .pipe(catchError((err) => this.handleError(err, willBlock, loadingBar)));
    }

    public httpDelete(
        url: string,
        headers: HttpHeaders,
        params: HttpParams,
        willBlock: Boolean = true,
        loadingBar: Boolean = true
    ): Observable<any> {
        if (willBlock) {
            this.blockUI.start(this.configuration.waitingMsg);
        }

        let token = localStorage.getItem('currentUserToken');
        let authVersion = localStorage.getItem('authorization-version');
        headers = headers.append('token', token);
        headers = headers.append('authorization-version', authVersion);
        headers = headers.append('Content-Type', 'application/json');

        return this.http
            .delete(this.configuration.ServerWithApiUrl + url, {
                headers: headers,
                params: params,
                observe: 'response',
            })
            .pipe(
                map((response) => {
                    //Check If Authorization Version has been changed Then Load all Pages,APIs, Menu again
                    if (localStorage.getItem('authorization-version') && localStorage.getItem('currentUserToken')) {
                        let serverAuthVersion = response.headers.get('authorization-version');
                        if (serverAuthVersion != localStorage.getItem('authorization-version')) {
                            let token = localStorage.getItem('currentUserToken');

                            //Get user Apis list and Page list and Menu list then  store in localstorage
                            this.getApisMenusPagesFromApi(token, serverAuthVersion);
                        }
                    }
                    return this.parseResponse(response, willBlock, loadingBar);
                })
            )
            .pipe(catchError((err) => this.handleError(err, willBlock, loadingBar)));
    }

    private handleError(err: any, blocking: Boolean, loadingBar: Boolean) {
        if (blocking) {
            this.blockUI.stop();
        }

        if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json') {
            // https://github.com/angular/angular/issues/19888
            // When request of type Blob, the error is also in Blob instead of object of the json data
            return new Promise<any>((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = (e: Event) => {
                    try {
                        const errmsg = JSON.parse((<any>e.target).result);
                        reject(
                            new HttpErrorResponse({
                                error: errmsg,
                                headers: err.headers,
                                status: err.status,
                                statusText: err.statusText,
                                url: err.url,
                            })
                        );
                    } catch (e) {
                        reject(err);
                    }
                };
                reader.onerror = (e) => {
                    reject(err);
                };
                reader.readAsText(err.error);
            });
        }
        return throwError(err);
    }

    private parseResponse(response: any, blocking: Boolean, loadingBar: Boolean) {
        if (blocking) {
            this.blockUI.stop();
        }

        return response;
    }

    private getApisMenusPagesFromApi(token, AuthorizationVersion) {
        let headers = new HttpHeaders();
        //Get Api
        this.httpGet('Authorization/GetApis', headers, null, true, false).subscribe(
            (data) => {
                var apis = data.body;
                localStorage.setItem('currentUserApis', JSON.stringify(apis));
            },
            (error) => {
                this.errorhandlerservice.handleHttpErrorWithSW(error);
            }
        );

        //Get pages
        this.httpGet('Authorization/GetPages', headers, null, true, false).subscribe(
            (data) => {
                var pages = data.body;
                localStorage.setItem('currentUserPages', JSON.stringify(pages));
            },
            (error) => {
                this.errorhandlerservice.handleHttpErrorWithSW(error);
            }
        );

        //Get Menus
        this.httpGet('Authorization/GetMenus', headers, null, true, false)
            .pipe(
                finalize(() => {
                    //Changing Menu
                    var newmenu = this.menuService.getJsonMenuFromLocalStorage();
                    this.menuDataService.updateMenu(newmenu);
                })
            )
            .subscribe(
                (data) => {
                    var menus = data.body;
                    let applicationVersion = data.headers.get('application-version');
                    localStorage.setItem('application-version', applicationVersion.toString());
                    localStorage.setItem('currentUserMenus', JSON.stringify(menus));
                },
                (error) => {
                    this.errorhandlerservice.handleHttpErrorWithSW(error);
                }
            );
    }
}
